import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import CheckRound from 'img/check-round.svg';

/**
 * The child component containing the text for the page to invite user
 * to check his emails after submitting the trial request.
 */

export default function TrialSignUpEmailSent() {
    const { t } = useTranslation();

    const { trackPageView } = useMatomo();

    // Track page view after submitting trial subscription
    useEffect(() => {
        trackPageView({
            documentTitle: 'Page check your email',
        });
    }, [trackPageView]);

    return (
        <>
            <div className="rods-text-container">
                <img className="rods-text-icon" src={CheckRound} alt="Checked logo" />
                <h3>{t('EmailValidation.Header')}</h3>
                <p>{t('EmailValidation.Text')}</p>
            </div>
        </>
    );
}
