import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react';
import App from './App';

import './i18next';

const instance = createInstance({
    urlBase: 'https://opendatasoft.matomo.cloud/',
    siteId: 4,
    // userId: 'UIDTRIAL42', // optional, default value: `undefined`.
    disabled: false, // optional, false by default. Makes all tracking calls no-ops if set to true.
    heartBeat: { // optional, enabled by default
        active: true, // optional, default value: true
        seconds: 10, // optional, default value: `15
    },
    linkTracking: false, // optional, default value: true
    configurations: { // optional, default value: {}
        // any valid matomo configuration, all below are optional
        disableCookies: true,
        setSecureCookie: true, // setSecureCookie to true when you leave localhost mode, only works with https
        setRequestMethod: 'POST',
    },
});

ReactDOM.render(
    <React.StrictMode>
        <MatomoProvider value={instance}>
            <Suspense fallback={null}>
                <App />
            </Suspense>
        </MatomoProvider>
    </React.StrictMode>,
    document.getElementById('root'),
);
