import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import CloseCircle from 'img/close-circle-fill.svg';

/**
 * The child component containing the text for the page to warn user
 * that something went wrong and to contact support team.
 */

export default function TrialSignUpError() {
    const { t } = useTranslation();

    const { trackPageView } = useMatomo();

    // Track page view after submitting trial subscription
    useEffect(() => {
        trackPageView({
            documentTitle: 'Page error on submit form',
        });
    }, [trackPageView]);

    return (
        <>
            <div className="rods-text-container">
                <img className="rods-text-icon" src={CloseCircle} alt="Close logo" />
                <h3>{t('ErrorOnCreation.Header')}</h3>
                <p>{t('ErrorOnCreation.Text')}</p>
            </div>
        </>
    );
}
