import React from 'react';
import classnames from 'classnames';
import { useField } from 'formik';
import PropTypes from 'prop-types';

/**
 * The TextInput is a standardized Opendatasoft text input, which supports validation and can also be used
 * to render a textarea.
 */

const TextInput = ({
    label, helptext, isInvalid, className, ...props
}) => {
    const [field, meta] = useField(props);

    const cssClasses = classnames([
        'rods-form-control',
        {
            'is-invalid': (!!(meta.touched && meta.error)),
        },
        className,
    ]);

    return (
        <>
            <label htmlFor={props.id || props.name}>{label}</label>
            <input className={cssClasses} {...field} {...props} />
            {meta.touched && meta.error ? (
                <div className="error">{meta.error}</div>
            ) : null}
        </>
    );
};

export default TextInput;

TextInput.propTypes = {
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    name: PropTypes.string.isRequired,
    /** The label of the text input */
    label: PropTypes.string,
    /** The helptext */
    helptext: PropTypes.string,
    /** Determine if it should use the invalid style */
    isInvalid: PropTypes.bool,
    /** Additional CSS classes to attach to the input */
    className: PropTypes.string,
};
TextInput.defaultProps = {
    id: '1',
    label: '',
    isInvalid: false,
    className: null,
    helptext: '',
};
