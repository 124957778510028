import React, { useEffect, useState } from 'react';
import StepTracker from 'components/StepTracker';

import TrialProfileForm from 'pages/TrialProfile/TrialProfileForm';
import TrialCompanyForm from 'pages/TrialProfile/TrialCompanyForm';

import { useHistory } from 'react-router-dom';
import i18n from 'i18next';

/**
 * The parent component for the modal displayed through an iframe in the trial's backoffice.
 */

export default function TrialProfile() {
    const history = useHistory();

    useEffect(() => {
        i18n.changeLanguage(history.location.pathname.split('/')[1]);
    }, [history.location]);

    const [activeIdx, setActiveIdx] = useState(0);

    const [data, setData] = useState({});

    const closeIframe = (value) => {
        const url = window.location !== window.parent.location
            ? document.referrer
            : document.location.href;
        window.parent.postMessage(value, url);
    };

    return (
        <div className="rods-profile-container">
            <StepTracker
                className="rods-profile-steptracker"
                steps={[
                    { title: 'none', key: 'step0' },
                    { title: 'none', key: 'step1' },
                ]}
                activeIdx={activeIdx}
            />
            {activeIdx === 0
                ? <TrialProfileForm setActiveIdx={setActiveIdx} data={data} setData={setData} />
                : (
                    <TrialCompanyForm
                        setActiveIdx={setActiveIdx}
                        data={data}
                        setData={setData}
                        closeIframe={closeIframe}
                    />
                )}
        </div>
    );
}
