import React, { useEffect, useRef, useLayoutEffect } from 'react';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Spinner from 'components/Spinner';
import config from '../../config';

/**
 * The child component for the page to check trial domain information
 * and to validate the domain creation. It's displayed if there was no error.
 */

export default function TrialConfirmCreation({ token, locale }) {
    const { t } = useTranslation();
    const submitForm = useRef(null);
    const { trackPageView } = useMatomo();

    // Track page view after submitting trial subscription
    useEffect(() => {
        trackPageView({
            documentTitle: 'Page confirm and create trial - valid',
        });
    }, [trackPageView]);

    useLayoutEffect(() => {
        /* eslint no-unused-expressions: ["error", { "allowShortCircuit": true }] */
        submitForm.current && submitForm.current.submit();
        return () => { submitForm.current = null; };
    }, []);

    // Confirm and create the trial platform

    return (
        <>
            <div className="rods-text-container">
                <h2>{t('TrialValidationValid.Header')}</h2>
                <p>{t('TrialValidationValid.Text')}</p>
                <Spinner size="24px" />
                <form
                    action={`${config.platformAddress}/api/trials/confirm/?token=${token}&flg=${locale}`}
                    method="post"
                    ref={submitForm}
                />
            </div>
        </>
    );
}

TrialConfirmCreation.propTypes = {
    token: PropTypes.string.isRequired,
    locale: PropTypes.string.isRequired,
};
