import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import CheckInput from '../CheckInput';
import './checkbox_input.scss';

const CheckboxInput = ({ children, ...props }) => {
    // React treats radios and checkbox inputs differently other input types, select, and textarea.
    // Formik does this too! When you specify `type` to useField(), it will
    // return the correct bag of props for you -- a `checked` prop will be included
    // in `field` alongside `name`, `value`, `onChange`, and `onBlur`
    const [field, meta] = useField({ ...props, type: 'checkbox' });
    return (
        <div>
            <div className="rods-checkbox-input">
                <CheckInput className="rods-checkbox-input" type="checkbox" {...field} {...props} />
                {children}
            </div>
            {meta.touched && meta.error ? (
                <div className="error">{meta.error}</div>
            ) : null}
        </div>
    );
};

export default CheckboxInput;

CheckboxInput.propTypes = {
    /**
     * CheckboxInput children
     */
    children: PropTypes.node,
};
CheckboxInput.defaultProps = {
    children: null,
};
