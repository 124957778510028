import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Spinner from 'components/Spinner';

import TrialImageBox from 'pages/TrialImagesBox';

import validateTrialRequest from 'utils/validateTrialRequest';
import { useHistory } from 'react-router-dom';
import TrialConfirmInvalid from './TrialConfirmInvalid';
import TrialConfirmCreation from './TrialConfirmCreation';

/**
 * The parent component for the page to check trial domain information
 * and to validate the domain creation. It's displayed after following the link in the request trial email.
 */

export default function TrialConfirm() {
    // Handling Loading, errors and response
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [response, setResponse] = useState('');

    const history = useHistory();
    const { i18n } = useTranslation();

    useEffect(() => {
        i18n.changeLanguage(history.location.pathname.split('/')[1]);
    }, [history.location, i18n]);

    // Fetch user's trial domain to display
    const { search } = window.location;
    const params = new URLSearchParams(search);
    const token = params.get('token');

    const callValidateTrialRequest = useCallback(async () => {
        setIsLoading(true);
        const result = await validateTrialRequest(token);
        if (
            (result === 'internal error')
            || result.expired
            || result.has_trial
            || result.trial_already_created) {
            setIsError(true);
            setResponse(result);
            setIsLoading(false);
        } else {
            setResponse(result);
            setIsLoading(false);
        }
    }, [token]);

    useEffect(() => {
        callValidateTrialRequest();
    }, [callValidateTrialRequest]);

    return (
        <div className="container">
            {isLoading
                ? <Spinner />
                : (
                    <>
                        <div className="row">
                            <TrialImageBox />
                        </div>
                        <div className="row">
                            {isError
                                ? <TrialConfirmInvalid response={response} />
                                : <TrialConfirmCreation locale={i18n.language} token={token} />}
                        </div>
                    </>
                )}
        </div>
    );
}
