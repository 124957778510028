import React, {
    useEffect, useState, useMemo, useCallback,
} from 'react';
import classnames from 'classnames';
import { useField } from 'formik';
import '../../../css/main.scss';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';

/**
 * The TextInput is a standardized Opendatasoft text input, which supports validation and can also be used
 * to render a textarea.
 */

const SuggestTextInput = ({
    label, helptext, isInvalid, className, onSuggest, autoComplete, ...props
}) => {
    const [field, meta, helpers] = useField(props);
    const { t } = useTranslation();
    const [desiredDomainId, setDesiredDomainId] = useState('');

    // State and functions to handle suggested domain Id
    const [errorSuggestDomainId, setErrorSuggestDomainId] = useState('');

    const handleDomainId = useCallback(async (values) => {
        if (values !== '' && values.length > 2) {
            const result = await onSuggest(values);
            if (result === 'internal error') {
                setErrorSuggestDomainId(t('SignUpFormErrors.DomainSuggestionError'));
            } else {
                setErrorSuggestDomainId('');
                helpers.setValue(result);
            }
        } else if (values !== '') {
            helpers.setValue(values);
            helpers.setTouched(true);
        } else {
            helpers.setValue(values);
        }
    }, [setErrorSuggestDomainId, t]);

    const delayedHandleChange = useMemo(() => debounce((e) => handleDomainId(e), 500), [handleDomainId]);

    // Cancel any schedulded calls when component unmounts
    useEffect(() => () => {
        delayedHandleChange.cancel();
    }, [delayedHandleChange]);

    // Check for field value changes to call the function
    useEffect(() => {
        const handleDomainIDDebounce = ((e) => {
            delayedHandleChange(e);
        });
        handleDomainIDDebounce(desiredDomainId);
    }, [desiredDomainId, delayedHandleChange]);

    const cssClasses = classnames([
        'rods-form-control',
        {
            'is-invalid': (!!(meta.touched && meta.error)),
        },
        className,
    ]);

    return (
        <>
            <label htmlFor={props.id || props.name}>{label}</label>
            <input
                className={cssClasses}
                value={desiredDomainId}
                autoComplete={autoComplete}
                onChange={(e) => setDesiredDomainId(e.target.value)}
            />
            {meta.touched && meta.error ? (
                <div className="error">{meta.error}</div>
            ) : null}
            {field.value.length > 2
                ? (
                    <div className="rods-platform-name-preview-ok">
                        <b>{t('SignUpForm.DomainSuggestion')}</b>
                        <br />
                        https://
                        {field.value}
                        .trial.opendatasoft.com
                    </div>
                )
                : <div />}
            <div className="error">{errorSuggestDomainId}</div>
        </>
    );
};

export default SuggestTextInput;

SuggestTextInput.propTypes = {
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    name: PropTypes.string.isRequired,
    /** The label of the text input */
    label: PropTypes.string,
    /** The helptext */
    helptext: PropTypes.string,
    /** Determine if it should use the invalid style */
    isInvalid: PropTypes.bool,
    /** Additional CSS classes to attach to the input */
    className: PropTypes.string,
    onSuggest: PropTypes.func.isRequired,
    /** Autocomplete attribute to add to the underlying input */
    autoComplete: PropTypes.string,
};

SuggestTextInput.defaultProps = {
    id: '1',
    label: '',
    isInvalid: false,
    className: null,
    helptext: '',
    autoComplete: null,
};
