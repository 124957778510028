import config from '../config';

const DOMAINS_API_PATH = '/api/domains/';

async function getSuggestDomainId(desiredDomain) {
    try {
        const response = await fetch(`${config.platformAddress}${DOMAINS_API_PATH}suggest-id/?name=${desiredDomain}`)
            .then((result) => result.json());
        return response.result;
    } catch (e) {
        const errorMessage = 'internal error';
        return errorMessage;
    }
}
export default getSuggestDomainId;
