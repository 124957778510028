import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './main-button.scss';

/**
 * The Button is a standardized trial Opendatasoft button, which supports loading state, and style variants.
 */
const Button = React.forwardRef((props, forwardedRef) => {
    const {
        tag, isLoading, loadingNode, type, variant, disabled,
        children, size, className, active, focus, ...otherProps
    } = props;
    const Tag = tag === 'a' && disabled ? 'button' : tag;

    const cssClasses = classnames([
        className,
        'rods-button',
        `rods-button--${variant}`,
        {
            [`rods-button--${size}`]: variant !== 'link',
            'rods-button--active': active,
            'rods-button--focus': focus,
        },
    ]);

    const renderLabel = () => {
        if (isLoading && loadingNode) {
            return <span>{loadingNode}</span>;
        }
        if (children) {
            return <span>{children}</span>;
        }
        return null;
    };
    const renderButton = (ref) => (
        <Tag
            disabled={isLoading || disabled}
            type={tag === 'button' ? type : undefined}
            className={cssClasses}
            aria-busy={isLoading}
            ref={ref}
            {...otherProps}
        >
            {renderLabel()}
        </Tag>
    );
    return renderButton(forwardedRef);
});

Button.propTypes = {
    /** The button content */
    children: PropTypes.node,
    /** Additional classNames to add the button */
    className: PropTypes.string,
    /** Which HTML tag to use */
    tag: PropTypes.string,
    /** Button loading state. If true, it will disable the button */
    isLoading: PropTypes.bool,
    /** Use loadingNode to customize the button content when it is loading */
    loadingNode: PropTypes.node,
    /** The type of the button */
    type: PropTypes.oneOf(['submit', 'reset', 'button']),
    /** The color variant of the button */
    variant: PropTypes.oneOf(
        ['primary', 'secondary', 'link'],
    ),
    /** The size of the button */
    size: PropTypes.oneOf(['100', '200', '300']),
    /** If the button is disabled */
    disabled: PropTypes.bool,
    /** If the button is in an active state */
    active: PropTypes.bool,
    /** If the button is in a focused state */
    focus: PropTypes.bool,
};

Button.defaultProps = {
    children: null,
    tag: 'button',
    isLoading: false,
    loadingNode: null,
    type: 'button',
    variant: 'primary',
    size: '200',
    disabled: false,
    active: false,
    focus: false,
    className: null,
};
Button.displayName = 'Button';
export default Button;
