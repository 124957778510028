import React from 'react';
import classnames from 'classnames';
import './select_input.scss';
import { useField } from 'formik';
import PropTypes from 'prop-types';

/**
 * SelectInput is a standardized select input. It supports validation and optgroups.
 * To render options, it can take a list of options or use its children.
 */
const SelectInput = ({
    label, isInvalid, className, variant, ...props
}) => {
    const [field, meta] = useField(props);
    const cssClasses = classnames([
        'rods-form-control',
        {
            'is-invalid': (!!(meta.touched && meta.error)),
        },
        `rods-select-input--${variant}-variant`,
        className,
    ]);
    return (
        <div>
            <label htmlFor={props.id || props.name}>{label}</label>
            <select className={cssClasses} {...field} {...props} />
            {meta.touched && meta.error ? (
                <div className="error">{meta.error}</div>
            ) : null}
        </div>
    );
};

export default SelectInput;

SelectInput.propTypes = {
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    name: PropTypes.string.isRequired,
    /** Additional CSS classes to attach to the input */
    className: PropTypes.string,
    /**
     * The input label attribute
     */
    label: PropTypes.string,
    /**
     * the type of input
     */
    variant: PropTypes.string,
    /**
     * Determine if the input should use the invalid style
     * */
    isInvalid: PropTypes.bool,
};

SelectInput.defaultProps = {
    id: '1',
    label: '',
    variant: '',
    isInvalid: false,
    className: null,
};
