/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { Formik, Form } from 'formik';
import TextInput from 'components/inputs/TextInput';
import SuggestTextInput from 'components/inputs/SuggestTextInput';
import SelectInput from 'components/inputs/SelectInput';
import Button from 'components/Button';
import Spinner from 'components/Spinner';
import CheckboxInput from 'components/inputs/CheckboxInput';
import * as Yup from 'yup';
import { useTranslation, Trans } from 'react-i18next';
import merge from 'lodash/merge';

import { useHistory } from 'react-router-dom';

import cldrFr from 'cldr-localenames-modern/main/fr/territories.json';
import cldrEn from 'cldr-localenames-modern/main/en/territories.json';
import cldrEs from 'cldr-localenames-modern/main/es/territories.json';
import cldrDe from 'cldr-localenames-modern/main/de/territories.json';
import cldrNl from 'cldr-localenames-modern/main/nl/territories.json';

import { useMatomo } from '@datapunt/matomo-tracker-react';
import createTrialRequest from 'utils/createTrialRequest';
import getSuggestDomainId from 'utils/suggestedDomainId';
import hubspotCountries from 'hubspot-countries';

/**
 * The form component for the Sign Up page.
 */

const TrialSignUpForm = () => {
    // State needed to send the suggested domain name to token from the SuggestTextInput
    const { t, i18n } = useTranslation();
    const { trackPageView } = useMatomo();
    const history = useHistory();

    // Function handling routing and language changing
    function changeLang(lang) {
        history.push(`/${lang}`);
    }

    // Track page view at entrance
    useEffect(() => {
        trackPageView({
            documentTitle: 'Page sign up form',
        });
    }, [trackPageView]);

    // Handling the select input with all countries depending of the language selected
    const cldrData = merge(cldrFr, cldrEn, cldrEs, cldrDe, cldrNl);

    const options = Object.keys(hubspotCountries).map((value) => ({
        value,
        label: cldrData.main[i18n.language].localeDisplayNames.territories[value],
    })).sort((a, b) => a.label.localeCompare(b.label));

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={{
                    email: '',
                    domain_name: '',
                    password: '',
                    country: '',
                    language: i18n.language,
                    terms_of_service: false,
                }}
                validationSchema={Yup.object({
                    email: Yup.string()
                        .email(t('SignUpFormErrors.InvalidEmail'))
                        .required(t('SignUpFormErrors.Required')),
                    domain_name: Yup.string()
                        .min(3, t('SignUpFormErrors.NameLength'))
                        .required(t('SignUpFormErrors.Required')),
                    password: Yup.string()
                        .required(t('SignUpFormErrors.Required'))
                        .test({
                            name: 'passwordlength',
                            test: (value) => !value || value.length > 11,
                            message: t('SignUpFormErrors.PasswordLength'),
                        })
                        .test({
                            name: 'passwordnumber',
                            test: (value) => /[0-9]/.test(value),
                            message: t('SignUpFormErrors.PasswordNumber'),
                        })
                        .test({
                            name: 'passwordletter',
                            test: (value) => /[a-z]/.test(value),
                            message: t('SignUpFormErrors.PasswordLetter'),
                        })
                        .test({
                            name: 'passwordspecialchar',
                            test: (value) => /[^a-zA-Z0-9]/.test(value),
                            message: t('SignUpFormErrors.PasswordSpecialChar'),
                        }),
                    terms_of_service: Yup.boolean()
                        .required(t('SignUpFormErrors.Required'))
                        .oneOf([true], t('SignUpFormErrors.TermsAndConditions')),
                    country: Yup.string()
                        .required(t('SignUpFormErrors.Required')),
                    language: Yup.string()
                        .required(t('SignUpFormErrors.Required')),
                })}
                onSubmit={(values, { setSubmitting }) => {
                    const payload = {
                        country: hubspotCountries[values.country],
                        domain_name: values.domain_name,
                        email: values.email,
                        language: values.language,
                        password: values.password,
                    };
                    const locale = i18n.language;
                    createTrialRequest(payload, locale)
                        .then((r) => {
                            if (r.error) {
                                history.push(`/${i18n.language}/creation-error`);
                            } else {
                                history.push(`/${i18n.language}/check-email`);
                            }
                            setSubmitting(false);
                        });
                }}
            >
                {({ isValid, dirty, isSubmitting }) => (
                    <div className="rods-form-container">
                        <div className="rods-lang-selector-area">
                            <button type="button" className={`rods-lang-selector-box${i18n.language === 'fr' ? '-selected' : ''}`} onClick={() => changeLang('fr')}>FR</button>
                            <button type="button" className={`rods-lang-selector-box${i18n.language === 'en' ? '-selected' : ''}`} onClick={() => changeLang('en')}>EN</button>
                            <button type="button" className={`rods-lang-selector-box${i18n.language === 'es' ? '-selected' : ''}`} onClick={() => changeLang('es')}>ES</button>
                            <button type="button" className={`rods-lang-selector-box${i18n.language === 'de' ? '-selected' : ''}`} onClick={() => changeLang('de')}>DE</button>
                        </div>
                        <Form name="sign-up-form">
                            <div className="rods-form-fieldset">
                                <TextInput
                                    label={t('SignUpForm.Email')}
                                    name="email"
                                    type="email"
                                    id="email"
                                />
                            </div>
                            <div className="rods-form-fieldset">
                                <TextInput
                                    label={t('SignUpForm.Password')}
                                    name="password"
                                    type="password"
                                    id="password"
                                />
                                <div className="rods-textinput-helptext">{t('SignUpForm.PasswordHelptext')}</div>
                            </div>
                            <div className="rods-form-fieldset">
                                <SuggestTextInput
                                    label={t('SignUpForm.PlatformName')}
                                    name="domain_name"
                                    type="text"
                                    id="domain_name"
                                    autoComplete="off"
                                    onSuggest={getSuggestDomainId}
                                />
                            </div>
                            <div className="rods-form-fieldset">
                                <SelectInput label={t('SignUpForm.YourCountry')} name="country" id="country">
                                    <option value="">-</option>
                                    <optgroup label={t('SignUpForm.YourCountryOptions.OptionGroup1')}>
                                        {options
                                            .filter((country) => (country.value === 'FR' || country.value === 'US'))
                                            .map((country) => <option key={country.value} value={country.value}>{country.label}</option>)}
                                    </optgroup>
                                    <optgroup label={t('SignUpForm.YourCountryOptions.OptionGroup2')}>
                                        {options
                                            .map((country) => <option key={country.value} value={country.value}>{country.label}</option>)}
                                    </optgroup>
                                </SelectInput>
                            </div>
                            <div className="rods-form-fieldset">
                                <SelectInput label={t('SignUpForm.Language')} name="language" id="language">
                                    <option value="en">{t('SignUpForm.LanguageOptions.English')}</option>
                                    <option value="fr">{t('SignUpForm.LanguageOptions.French')}</option>
                                    <option value="de">{t('SignUpForm.LanguageOptions.German')}</option>
                                    <option value="es">{t('SignUpForm.LanguageOptions.Spanish')}</option>
                                    <option value="nl">{t('SignUpForm.LanguageOptions.Dutch')}</option>
                                </SelectInput>
                            </div>
                            <div className="rods-form-fieldset">
                                <CheckboxInput name="terms_of_service">
                                    <div>
                                        <Trans i18nKey="SignUpForm.Checkbox">
                                            I have read and accepted the
                                            <a target="_blank" rel="noreferrer" href={`https://legal.opendatasoft.com/${i18n.language === 'fr' ? 'fr' : 'en'}_FR/privacy-policy.html`}> privacy policy</a>
                                            and
                                            <a target="_blank" rel="noreferrer" href={`https://legal.opendatasoft.com/${i18n.language === 'fr' ? 'fr' : 'en'}_FR/terms-of-services-freemium.html`}> general terms</a>
                                            and
                                            <a target="_blank" rel="noreferrer" href={`https://legal.opendatasoft.com/${i18n.language === 'fr' ? 'fr' : 'en'}_FR/terms-of-use.html`}> conditions of use</a>
                                            of Opendatasoft Platform services and accept to receive commercial offers from Opendatasoft.
                                        </Trans>
                                    </div>
                                </CheckboxInput>
                            </div>
                            <div className="rods-form-fieldset">
                                <Button className="rods-button-loading" size="300" type="submit" disabled={!dirty || !isValid || isSubmitting}>
                                    <Spinner className={!isSubmitting ? 'spinner spinner-loading' : 'spinner'} />
                                    <span className={isSubmitting ? 'loading' : ''}>{t('SignUpForm.ButtonSubmit')}</span>
                                </Button>
                            </div>
                        </Form>
                    </div>
                )}
            </Formik>
        </>
    );
};

export default TrialSignUpForm;
