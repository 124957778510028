import React from 'react';

import TrialImageBox from 'pages/TrialImagesBox';
import TrialSignUpEmailSent from './TrialSignUpEmailSent';

/**
 * The parent component for the page to invite user to check his emails.
 * It's the second page displayed after submitting the trial request.
 */

export default function TrialSignUp() {
    return (
        <div className="container">
            <div className="row">
                <TrialImageBox />
            </div>
            <div className="row">
                <TrialSignUpEmailSent />
            </div>

        </div>
    );
}
