import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useSpring, animated } from 'react-spring';

import './step-tracker.scss';
import Button from 'components/Button';

const StepNumber = ({
    isCompleted, stepNumber, onClick, variant,
}) => {
    const icon = isCompleted ? (
        <svg
            className="rods-step-tracker__completed-icon"
            focusable="false"
            viewBox="0 0 24 24"
            aria-hidden="true"
            role="presentation"
        >
            <path d="M12 0a12 12 0 1 0 0 24 12 12 0 0 0 0-24zm-2 17l-5-5 1.4-1.4 3.6 3.6 7.6-7.6L19 8l-9 9z" />
        </svg>
    ) : (
        <span className="rods-step-tracker__step-number-text">
            {stepNumber}
            .
            {variant === 'small' ? '.' : null}
        </span>
    );
    if (onClick) {
        return (
            <Button variant="link" onClick={onClick} className="rods-step-tracker__step-number">
                {icon}
            </Button>
        );
    }
    return (
        <div className="rods-step-tracker__step-number">{icon}</div>
    );
};

StepNumber.propTypes = {
    isCompleted: PropTypes.bool.isRequired,
    stepNumber: PropTypes.number.isRequired,
    onClick: PropTypes.func,
    variant: PropTypes.oneOf(['large', 'small']).isRequired,
};

StepNumber.defaultProps = {
    onClick: null,
};

const Step = ({
    step, isActive, isCompleted, idx, onClick, variant, stepsLength,
}) => {
    const animConnecProps = useSpring({
        width: isCompleted || (isActive && variant === 'large') ? '100%' : '0%',
    });
    const onStepClick = useCallback(() => onClick && onClick(step, idx), [idx, onClick, step]);
    const titleElem = onClick
        ? (
            <Button variant="link" className="rods-step-tracker__title" onClick={onStepClick}>
                {step.title}
            </Button>
        )
        : <span className="rods-step-tracker__title">{step.title}</span>;

    const connectorElem = (
        <div className="rods-step-tracker__connector">
            <animated.div className="rods-step-tracker__connector-line" style={animConnecProps} />
        </div>
    );
    return (
        <>
            <div className="rods-step-tracker__step-root">
                {idx > 0 && variant === 'large' && connectorElem}
                <div className={classnames('rods-step-tracker__step', {
                    'rods-step-tracker__step--active': isActive,
                    'rods-step-tracker__step--completed': isCompleted,
                })}
                >
                    <StepNumber
                        stepNumber={idx + 1}
                        variant={variant}
                        isCompleted={isCompleted}
                        onClick={onClick ? onStepClick : null}
                    />
                    {(variant === 'large' || isActive) && titleElem.props.children !== 'none' ? titleElem : null}
                </div>
            </div>
            {idx < stepsLength - 1 && variant === 'small' && connectorElem}
        </>
    );
};

Step.propTypes = {
    step: PropTypes.shape({
        title: PropTypes.string.isRequired,
    }).isRequired,
    isActive: PropTypes.bool.isRequired,
    isCompleted: PropTypes.bool.isRequired,
    idx: PropTypes.number.isRequired,
    stepsLength: PropTypes.number.isRequired,
    onClick: PropTypes.func,
    variant: PropTypes.oneOf(['large', 'small']).isRequired,
};

Step.defaultProps = {
    onClick: null,
};

/**
 * StepTracker is a UI component to display progress through a sequence of logical and numbered steps.
 * They can be clickable.
 */
function StepTracker({
    steps, activeIdx, onClick, canClick, className, variant,
}) {
    return (
        <div className={classnames('rods-step-tracker', `rods-step-tracker--${variant}`, className)}>
            {steps.map((step, idx) => (
                <Step
                    variant={variant}
                    step={step}
                    key={step.key}
                    idx={idx}
                    stepsLength={steps.length}
                    onClick={!canClick || canClick(step, idx) ? onClick : null}
                    isActive={idx === activeIdx}
                    isCompleted={activeIdx > idx}
                />
            ))}
        </div>
    );
}

StepTracker.propTypes = {
    /** The steps to display */
    steps: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string.isRequired,
        key: PropTypes.string.isRequired,
    })).isRequired,
    /** The current, active step */
    activeIdx: PropTypes.number.isRequired,
    /** Style variant */
    variant: PropTypes.oneOf(['large', 'small']),
    /** A function to called when a step is clicked. Called with (step, stepIdx) */
    onClick: PropTypes.func,
    /** A function to called to determine whether a step is clickable or not: (step, stepIdx) => bool */
    canClick: PropTypes.func,
    /** Additional CSS classes to attach to the step tracker */
    className: PropTypes.string,
};

StepTracker.defaultProps = {
    variant: 'large',
    onClick: null,
    canClick: null,
    className: null,
};

export default StepTracker;
