import React from 'react';

import TrialImageBox from 'pages/TrialImagesBox';
import TrialSignUpError from './TrialSignUpError';

/**
 * The parent component for the page to warn user that something went wrong and contact support team.
 * It's the second page displayed if an error occured after submitting the trial request.
 */

export default function TrialCreationError() {
    return (
        <div className="container">
            <div className="row">
                <TrialImageBox />
            </div>
            <div className="row">
                <TrialSignUpError />
            </div>

        </div>
    );
}
