import React, { useEffect } from 'react';

import TrialImageBox from 'pages/TrialImagesBox';
import TrialSignUpForm from 'pages/TrialSignUp/TrialSignUpForm';

import { useHistory } from 'react-router-dom';
import i18n from 'i18next';

/**
 * The parent component for the form of the Sign Up page.
 * It's the first page displayed and the entry point to request a trial
 */

export default function TrialSignUp() {
    const history = useHistory();

    useEffect(() => {
        i18n.changeLanguage(history.location.pathname.split('/')[1]);
    }, [history.location]);

    return (
        <div className="container">
            <div className="row">
                <TrialImageBox />
            </div>
            <div className="row">
                <TrialSignUpForm />
            </div>

        </div>
    );
}
