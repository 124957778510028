import 'css/main.scss';
import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Redirect,
    Route,
} from 'react-router-dom';
import TrialConfirm from 'pages/TrialConfirm/TrialConfirm';
import TrialProfile from 'pages/TrialProfile/TrialProfile';

import { useTranslation } from 'react-i18next';
import TrialCheckEmail from './pages/TrialCheckEmail/TrialCheckEmail';
import TrialCreationError from './pages/TrialCreationError/TrialCreationError';
import TrialSignUp from './pages/TrialSignUp/TrialSignUp';

function App() {
    const trialLanguages = ['fr', 'en', 'es', 'de'];
    const { i18n } = useTranslation();

    return (
        <Router>
            <Switch>
                <Redirect exact from="/" to={`/${i18n.language}`} />
                {trialLanguages.map((language) => (
                    <Route key={language} exact path={`/${language}`}>
                        <TrialSignUp />
                    </Route>
                ))}
                {trialLanguages.map((language) => (
                    <Route key={language} exact path={`/${language}/check-email`}>
                        <TrialCheckEmail />
                    </Route>
                ))}
                {trialLanguages.map((language) => (
                    <Route key={language} exact path={`/${language}/creation-error`}>
                        <TrialCreationError />
                    </Route>
                ))}
                {trialLanguages.map((language) => (
                    <Route key={language} exact path={`/${language}/confirm`}>
                        <TrialConfirm />
                    </Route>
                ))}
                {trialLanguages.map((language) => (
                    <Route key={language} exact path={`/${language}/profile`}>
                        <TrialProfile />
                    </Route>
                ))}
            </Switch>
        </Router>
    );
}

export default App;
