/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { Formik, Form } from 'formik';
import TextInput from 'components/inputs/TextInput';
import SelectInput from 'components/inputs/SelectInput';
import Button from 'components/Button';
import Spinner from 'components/Spinner';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { useMatomo } from '@datapunt/matomo-tracker-react';

/**
 * The child component for the modal displayed through an
 * iframe in the trial's backoffice, containing the first step form to complete user's profile.
 */

const TrialProfileForm = ({ setActiveIdx, data, setData }) => {
    const { t } = useTranslation();
    const { trackPageView } = useMatomo();

    // Track page view at entrance
    useEffect(() => {
        trackPageView({
            documentTitle: 'Page Profile',
        });
    }, [trackPageView]);

    return (
        <>
            <Formik
                enableReinitialize
                validateOnMount
                initialValues={{
                    first_name: data.first_name || '',
                    last_name: data.last_name || '',
                    intention: data.intention || '',
                }}
                validationSchema={Yup.object({
                    first_name: Yup.string()
                        .required(t('SignUpFormErrors.Required')),
                    last_name: Yup.string()
                        .required(t('SignUpFormErrors.Required')),
                    intention: Yup.string()
                        .required(t('SignUpFormErrors.Required')),
                })}
                onSubmit={(values, { setSubmitting }) => {
                    setData({
                        ...data,
                        ...values,
                    });
                    setActiveIdx(1);
                    setSubmitting(false);
                }}
            >
                {({ isValid, isSubmitting, values }) => (
                    <>
                        <h1>{t('TrialProfile.Header')}</h1>
                        <p>{t('TrialProfile.Text')}</p>
                        <Form name="profile-form" className="rods-profile-form">
                            <div className="rods-form-fieldset">

                                <TextInput
                                    label={t('TrialProfile.FirstName')}
                                    name="first_name"
                                    type="text"
                                />
                            </div>
                            <div className="rods-form-fieldset">
                                <TextInput
                                    label={t('TrialProfile.LastName')}
                                    name="last_name"
                                    type="text"
                                />
                            </div>
                            <div className="rods-form-fieldset">
                                <SelectInput label={t('TrialProfile.Activity')} name="intention">
                                    <option value="">-</option>
                                    <option value="To discover some features by myself">{t('TrialProfile.ActivityOptions.Features')}</option>
                                    <option value="To benchmark Opendatasoft's solution and understand its comparative advantages">{t('TrialProfile.ActivityOptions.Benchmark')}</option>
                                    <option value="To build a demo to convince my colleagues/hierarchy this is the tool we need">{t('TrialProfile.ActivityOptions.Demo')}</option>
                                    <option value="Just to see how it looks like">{t('TrialProfile.ActivityOptions.Tosee')}</option>
                                    <option value="None of the above">{t('TrialProfile.ActivityOptions.None')}</option>
                                </SelectInput>
                            </div>
                            <div className="rods-form-fieldset">
                                <Button className="rods-button-loading" size="300" type="submit" disabled={!isValid || values.first_name === '' || isSubmitting}>
                                    <Spinner className={!isSubmitting ? 'spinner-secondary spinner-loading' : 'spinner-secondary'} />
                                    <span className={isSubmitting ? 'loading' : ''}>{t('TrialCompany.ButtonNext')}</span>
                                </Button>
                            </div>
                        </Form>
                    </>
                )}
            </Formik>
        </>
    );
};

export default TrialProfileForm;

TrialProfileForm.propTypes = {
    setActiveIdx: PropTypes.func.isRequired,
    data: PropTypes.objectOf(PropTypes.string),
    setData: PropTypes.func.isRequired,
};
TrialProfileForm.defaultProps = {
    data: {},
};
