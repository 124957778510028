import React from 'react';
import IntroImage from 'img/intro.jpeg';
import LogoImage from 'img/logo-ods_dark.svg';
import DataImage from 'img/jeux-de-donnees.svg';
import HistogramImage from 'img/Histogram_TitleAxis.svg';
import { useTranslation } from 'react-i18next';

/**
 * The component displaying images on the left side of the page.
 */

export default function TrialImageBox() {
    const { t } = useTranslation();
    return (
        <div className="rods-images-container">
            <div className="rods-logobox">
                <div className="rods-logobox-content">
                    <img className="rods-logo" src={LogoImage} alt="Logo OpenDataSoft" />
                    <div className="rods-text">{t('LogoBanner.Creation')}</div>
                    <div className="rods-greenbutton">{t('LogoBanner.TrialLength')}</div>
                </div>
            </div>
            <img src={HistogramImage} className="rods-image-histo" alt="HistogramImage" />
            <img src={IntroImage} className="rods-image-intro" alt="Intro" />
            <img src={DataImage} className="rods-image-data" alt="DataImage" />
        </div>
    );
}
