import React, { useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import Button from 'components/Button';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import Spinner from 'components/Spinner';
import config from '../../config';

/**
 * The child component for the page to check trial domain information
 * and to validate the domain creation. It's displayed if an error occurred.
 */

export default function TrialConfirmInvalid({ response }) {
    const { t } = useTranslation();

    const { trackPageView } = useMatomo();

    const [typeError, setTypeError] = useState('');
    const [typeHeader, setTypeHeader] = useState('Header');

    const history = useHistory();
    const redirectToHome = () => {
        history.push('/');
    };

    // Track page view after submitting trial subscription
    useEffect(() => {
        trackPageView({
            documentTitle: 'Page confirm and create trial - invalid',
        });
    }, [trackPageView]);
    const redirectToTrial = (domainName) => {
        /* eslint-disable-next-line max-len */
        window.location.href = `${config.httpPrefix}${domainName}${config.trialSubdomainSuffix}/backoffice/catalog/datasets/?sort=modified`;
    };

    useEffect(() => {
        if (response.expired) {
            setTypeError('Expired');
        } else if (response.trial_already_created) {
            setTypeError('TrialAlreadyCreated');
            setTypeHeader('HeaderAlreadyCreated');
            redirectToTrial(response.domain_name);
        } else if (response.has_trial) {
            setTypeError('HasTrial');
        } else {
            setTypeError('Unknown');
        }
    }, [response]);

    return (
        <>
            <div className="rods-text-container">
                <h2>{t(`TrialValidationInvalid.${typeHeader}`)}</h2>

                {typeError.length > 0
                    ? (
                        <>
                            <p>{t(`TrialValidationInvalid.${typeError}`)}</p>
                            {typeError === 'TrialAlreadyCreated' ? <Spinner size="24px" /> : null}
                        </>
                    )
                    : <div />}
                {typeError !== 'TrialAlreadyCreated'
                    ? (
                        <>
                            <div className="rods-invalid-info-last-child">
                                <Trans i18nKey="TrialValidationInvalid.NewRequest">
                                    Please
                                    <a rel="noreferrer" href="/">fill a new request</a>
                                </Trans>
                            </div>
                            <div className="rods-form-fieldset">
                                <Button size="300" type="submit" onClick={redirectToHome}>
                                    {t('TrialValidationInvalid.Button')}
                                </Button>
                            </div>
                        </>
                    )
                    : null}
            </div>
        </>
    );
}

TrialConfirmInvalid.propTypes = {
    response: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
            domain_name: PropTypes.string,
            has_trial: PropTypes.bool,
            trial_already_created: PropTypes.bool,
            expired: PropTypes.bool,
        })]).isRequired,
};
