/* eslint-disable max-len */
/* eslint-disable consistent-return */
import React, { useEffect } from 'react';
import { Formik, Form } from 'formik';
import TextInput from 'components/inputs/TextInput';
import SelectInput from 'components/inputs/SelectInput';
import Button from 'components/Button';
import Spinner from 'components/Spinner';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { useMatomo } from '@datapunt/matomo-tracker-react';

/**
 * The child component for the modal displayed through
 * an iframe in the trial's backoffice, containing the second step form to complete user's company details.
 */

const TrialCompanyForm = ({
    setActiveIdx, data, setData, closeIframe,
}) => {
    const { t } = useTranslation();
    const { trackPageView } = useMatomo();

    // Creates form data on industry types
    const publicIndustry = [
        { value: 'Central Government & Agencies', label: t('TrialCompany.SectorOptions.Central') },
        { value: 'Local Government & Agencies', label: t('TrialCompany.SectorOptions.Local') },
        { value: 'International Organizations', label: t('TrialCompany.SectorOptions.International') },
    ];
    const privateIndustry = [
        { value: 'Utilities, Water, Waste', label: t('TrialCompany.SectorOptions.Utilities') },
        { value: 'Transportation, Infrastructure and Public-Private Partnerships', label: t('TrialCompany.SectorOptions.Transportation') },
        { value: 'Industry & OEMs', label: t('TrialCompany.SectorOptions.Industry') },
        { value: 'Bank, Finance, Insurance', label: t('TrialCompany.SectorOptions.Bank') },
        { value: 'Other B2B', label: t('TrialCompany.SectorOptions.OtherB2B') },
        { value: 'Retail, Consumer & Other B2C', label: t('TrialCompany.SectorOptions.Retail') },
        { value: 'Non-Profits & Educations', label: t('TrialCompany.SectorOptions.NonProfits') },
    ];

    // Track page view at entrance
    useEffect(() => {
        trackPageView({
            documentTitle: 'Page Company',
        });
    }, [trackPageView]);

    // Saves input on Previous button and changes page

    function goBack(values) {
        if (publicIndustry.some((element) => element.value === values.industry)) {
            setData({
                ...data,
                ...values,
                department_private_sector: '',
            });
        } else {
            setData({
                ...data,
                ...values,
                department_public_sector: '',
            });
        }
        setActiveIdx(0);
    }

    return (
        <>
            <Formik
                initialValues={{
                    company: data.company || '',
                    company_size: data.company_size || '',
                    industry: data.industry || '',
                    department_public_sector: data.department_public_sector || '',
                    department_private_sector: data.department_private_sector || '',
                }}
                validationSchema={Yup.object({
                    company: Yup.string()
                        .required(t('SignUpFormErrors.Required')),
                    company_size: Yup.string()
                        .required(t('SignUpFormErrors.Required')),
                    industry: Yup.string()
                        .required(t('SignUpFormErrors.Required')),
                    department_public_sector: Yup.string()
                        .when('industry', {
                            is: (industry) => {
                                if (publicIndustry.some((element) => element.value === industry)) { return true; }
                            },
                            then: Yup.string()
                                .required(t('SignUpFormErrors.Required')),
                        }),
                    department_private_sector: Yup.string()
                        .when('industry', {
                            is: (industry) => {
                                if (privateIndustry.some((element) => element.value === industry)) { return true; }
                            },
                            then: Yup.string()
                                .required(t('SignUpFormErrors.Required')),
                        }),
                })}
                onSubmit={(values, { setSubmitting }) => {
                    if (publicIndustry.some((element) => element.value === values.industry)) {
                        const incrementedData = {
                            ...data,
                            ...values,
                            department_private_sector: '',
                        };
                        closeIframe(incrementedData);
                    } else {
                        const incrementedData = {
                            ...data,
                            ...values,
                            department_public_sector: '',
                        };
                        closeIframe(incrementedData);
                    }
                    setSubmitting(false);
                }}
            >
                {({ isValid, isSubmitting, values }) => (
                    <>
                        <h1>{t('TrialCompany.Header')}</h1>
                        <p>{t('TrialCompany.Text')}</p>
                        <Form name="profile-form" className="rods-profile-form">
                            <div className="rods-form-fieldset">
                                <TextInput
                                    label={t('TrialCompany.OrganizationName')}
                                    name="company"
                                    type="text"
                                />
                            </div>
                            <div className="rods-form-fieldset">
                                <SelectInput label={t('TrialCompany.OrganizationSize')} name="company_size">
                                    <option value="">-</option>
                                    <option value="1 - 100">1 - 100</option>
                                    <option value="101 - 200">101 - 200</option>
                                    <option value="201 - 500">201 - 500</option>
                                    <option value="501 - 1000">501 - 1000</option>
                                    <option value="1001 - 3000">1001 - 3000</option>
                                    <option value="3001 - 5000">3001 - 5000</option>
                                    <option value="5001 - 10000">5001 - 10000</option>
                                    <option value="10001+">10001+</option>
                                </SelectInput>
                            </div>
                            <div className="rods-form-fieldset">
                                <SelectInput label={t('TrialCompany.Sector')} name="industry">
                                    <option value="">-</option>
                                    {
                                        publicIndustry.map((element) => <option key={element.value} value={element.value}>{element.label}</option>)
                                    }
                                    {
                                        privateIndustry.map((element) => <option key={element.value} value={element.value}>{element.label}</option>)
                                    }
                                </SelectInput>
                            </div>
                            {
                                publicIndustry.some((element) => element.value === values.industry)
                                    ? (
                                        <div className="rods-form-fieldset">
                                            <SelectInput label={t('TrialCompany.Department')} name="department_public_sector">
                                                <option value="">-</option>
                                                <option value="Public Office, Elected Official & Staff, City Manager">{t('TrialCompany.DepartmentPublicOptions.PublicOffice')}</option>
                                                <option value="Change, Culture, Organization">{t('TrialCompany.DepartmentPublicOptions.Change')}</option>
                                                <option value="Communication, Development, Promotion">{t('TrialCompany.DepartmentPublicOptions.Communication')}</option>
                                                <option value="Public Administration & City Services">{t('TrialCompany.DepartmentPublicOptions.PublicAdministration')}</option>
                                                <option value="IT, Data Architecture, Data Science">{t('TrialCompany.DepartmentPublicOptions.IT')}</option>
                                                <option value="Innovation, New services">{t('TrialCompany.DepartmentPublicOptions.Innovation')}</option>
                                                <option value="Other">{t('TrialCompany.DepartmentPublicOptions.Other')}</option>
                                            </SelectInput>
                                        </div>
                                    )
                                    : <div />
                            }
                            {
                                privateIndustry.some((element) => element.value === values.industry)
                                    ? (
                                        <div className="rods-form-fieldset">
                                            <SelectInput label={t('TrialCompany.Department')} name="department_private_sector">
                                                <option value="">-</option>
                                                <option value="Change, Culture, Organization">{t('TrialCompany.DepartmentPrivateOptions.Change')}</option>
                                                <option value="Communication & External Relations">{t('TrialCompany.DepartmentPrivateOptions.Communication')}</option>
                                                <option value="Regulation, Compliance">{t('TrialCompany.DepartmentPrivateOptions.Regulation')}</option>
                                                <option value="Strategy & Transformation">{t('TrialCompany.DepartmentPrivateOptions.Strategy')}</option>
                                                <option value="Innovation, New Products, Incubation & Labs">{t('TrialCompany.DepartmentPrivateOptions.Innovation')}</option>
                                                <option value="IT, Data Architecture, Data Science">{t('TrialCompany.DepartmentPrivateOptions.IT')}</option>
                                                <option value="CSR, Sustainability, Impact">{t('TrialCompany.DepartmentPrivateOptions.CSR')}</option>
                                                <option value="Other">{t('TrialCompany.DepartmentPrivateOptions.Other')}</option>
                                                ,
                                            </SelectInput>
                                        </div>
                                    )
                                    : <div />
                            }
                            <div className="rods-form-fieldset">
                                <Button className="rods-button-loading" variant="secondary" size="300" onClick={() => goBack(values)}>
                                    <Spinner className={!isSubmitting ? 'spinner spinner-loading' : 'spinner'} />
                                    <span className={isSubmitting ? 'loading' : ''}>{t('TrialCompany.ButtonPrevious')}</span>
                                </Button>
                                <Button className="rods-button-loading" size="300" type="submit" disabled={values.company === '' || !isValid || isSubmitting}>
                                    <Spinner className={!isSubmitting ? 'spinner-secondary spinner-loading' : 'spinner-secondary'} />
                                    <span className={isSubmitting ? 'loading' : ''}>{t('TrialCompany.ButtonNext')}</span>
                                </Button>
                            </div>
                        </Form>
                    </>
                )}
            </Formik>
        </>
    );
};

export default TrialCompanyForm;

TrialCompanyForm.propTypes = {
    setActiveIdx: PropTypes.func.isRequired,
    data: PropTypes.objectOf(PropTypes.string),
    setData: PropTypes.func.isRequired,
    closeIframe: PropTypes.func.isRequired,
};
TrialCompanyForm.defaultProps = {
    data: {},
};
