import { useState } from 'react';
import { isNil } from 'lodash';
// https://github.com/facebook/react/issues/1137
// This utility can be used to generate simple identifier (to link label and inputs for example).
// If some day we do server rendering, the server root component will need to resetIdCounter()

let current = 0;

export default function generateId(prefix) {
    return `${prefix || 'id'}-${current++}`;
}

export function useUniqueId(propId, prefix = '') {
    const [uniqueId] = useState(generateId(prefix));
    return isNil(propId) ? uniqueId : propId;
}

export function resetIdCounter() { current = 0; }
