import config from '../config';

const TRIALS_API_PATH = '/api/trials/';

async function validateTrialRequest(token) {
    try {
        const response = await fetch(`${config.platformAddress}${TRIALS_API_PATH}confirm/?token=${token}`)
            .then((result) => result.json());
        return response;
    } catch (e) {
        const errorMessage = 'internal error';
        return errorMessage;
    }
}
export default validateTrialRequest;
