import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useUniqueId } from 'utils/uniqueId';

/**
 * CheckInput is the base input used to build custom checkboxes and radio inputs.
 * You probably should not use this component directly. See CheckboxInput and RadioInput.
 */
const CheckInput = React.forwardRef(
    ({
        className, children, isInvalid, checked, id, onChange, disabled, type, name, value,
        onBlur, onFocus,
    }, ref) => {
        const uniqueId = useUniqueId(id);

        const wrapperCssClasses = classnames([
            'rods-check-input', className,
            {
                'rods-check-input--is-invalid': isInvalid,
                'rods-check-input--no-label': !children,
            },
        ]);

        return (
            <div className={wrapperCssClasses}>
                <input
                    className="rods-check-input__input"
                    onBlur={onBlur}
                    onFocus={onFocus}
                    type={type}
                    value={value}
                    onChange={onChange}
                    name={name}
                    disabled={disabled}
                    checked={checked}
                    id={uniqueId}
                    ref={ref}
                />
                <label
                    className="rods-check-input__label"
                    htmlFor={uniqueId}
                >
                    {children}
                </label>
            </div>
        );
    },
);

CheckInput.propTypes = {
    /** Additional CSS classes to attach to the input */
    className: PropTypes.string,
    /**
     * Determine if the input is checked
     */
    checked: PropTypes.bool,
    /**
     * Determine the disable state of the input
     */
    disabled: PropTypes.bool,
    /**
     * The handler to be called when the input value changes, called with (event).
     */
    onChange: PropTypes.func,
    /**
     * The handler to be called when the input is focused
     */
    onFocus: PropTypes.func,
    /**
     * The handler to be called when the input is blurred
     */
    onBlur: PropTypes.func,
    /**
     * The input name attribute
     */
    name: PropTypes.string.isRequired,
    /**
     * The input id attribute
     */
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    /**
     * The input value attribute.
     */
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
    /**
     * the type of input (checkbox or radio)
     */
    type: PropTypes.oneOf(['radio', 'checkbox']),
    /**
     * Determine if the input should use the invalid style
     * */
    isInvalid: PropTypes.bool,
    /**
     * CheckInput children are used for the label
     */
    children: PropTypes.node,
};

CheckInput.defaultProps = {
    className: null,
    onChange: null,
    id: null,
    disabled: false,
    checked: false,
    type: 'checkbox',
    isInvalid: false,
    children: null,
    value: undefined,
    onBlur: null,
    onFocus: null,
};

CheckInput.displayName = 'CheckInput';

export default CheckInput;
