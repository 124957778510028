import config from '../config';

const TRIALS_API_PATH = '/api/trials/';

async function createTrialRequest(payload, locale) {
    try {
        await fetch(`${config.platformAddress}${TRIALS_API_PATH}?flg=${locale}`, {
            method: 'POST',
            body: JSON.stringify(payload),
        })
            .then((result) => result.json());
        return { result: 'ok' };
    } catch (e) {
        return { error: 'An error occured sending your request' };
    }
}
export default createTrialRequest;
