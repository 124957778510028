import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './spinner.scss';
import { isSVGAnimatable } from 'utils/dom';

function IESpinner({
    className, size, variant,
}) {
    return (
        <div
            className={classnames('rods-spinner', `rods-spinner--${variant}`, className)}
            style={{ height: size, width: size }}
            aria-live="polite"
            aria-label="Loading in progress"
            aria-busy="true"
        >
            <div className="rods-spinner__circle rods-spinner__circle--c1" />
            <div className="rods-spinner__circle rods-spinner__circle--c2" />
            <div className="rods-spinner__circle rods-spinner__circle--c3" />
            <div className="rods-spinner__circle rods-spinner__circle--c4" />
        </div>
    );
}

IESpinner.propTypes = {
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    className: PropTypes.string,
    variant: PropTypes.oneOf(['light-700', 'light-100', 'identity-200', 'polychrome']).isRequired,
};

IESpinner.defaultProps = {
    className: null,
};

/**
 * The Opendatasoft spinner as an inline SVG
 */
function Spinner({
    className, size, variant,
}) {
    if (!isSVGAnimatable()) {
        return <IESpinner className={className} size={size} variant={variant} />;
    }

    return (
        <svg
            viewBox="0 0 34 34"
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            className={classnames('rods-spinner', `rods-spinner--${variant}`, className)}
            style={{ height: size, width: size }}
            aria-live="polite"
            aria-label="Loading in progress"
            aria-busy="true"
        >
            <circle
                className="rods-spinner__circle rods-spinner__circle--c1"
                cx="7.5"
                cy="7.5"
                r="7.5"
            />
            <circle
                className="rods-spinner__circle rods-spinner__circle--c2"
                cx="7.5"
                cy="7.5"
                r="7.5"
            />
            <circle
                className="rods-spinner__circle rods-spinner__circle--c3"
                cx="7.5"
                cy="7.5"
                r="7.5"
            />
            <circle
                className="rods-spinner__circle rods-spinner__circle--c4"
                cx="7.5"
                cy="7.5"
                r="7.5"
            />
        </svg>
    );
}

Spinner.propTypes = {
    /** The spinner height and width */
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /** Additional classNames to add the svg */
    className: PropTypes.string,
    /** The spinner colors */
    variant: PropTypes.oneOf(['light-700', 'light-100', 'identity-200', 'polychrome']),
    /**
     *
     * @ignore
     * */
};

Spinner.defaultProps = {
    size: '14px',
    className: null,
    variant: 'polychrome',
};

export default Spinner;
